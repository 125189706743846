<template>
  <div class="row">
    <div class="col-md-12">
      <template>
        <v-card>
          <v-card-title>
            <download-excel
                v-show="isExportable"
                class="btn btn-default"
                :data="reservationsFullList.data"
                :fields="json_fields"
                worksheet="Esportazione"
                name="reservations.xls"
            >
              {{ $t("PAGES.EVENTS.LIST.DOWNLOAD_XLS_BUTTON") }}
            </download-excel>
            <!--            <v-btn color="success" class="mr-4" @click="exportXls()">-->
            <!--              {{ $t("COMMON.BUTTON.EXPORT_XLS") }}-->
            <!--            </v-btn>-->
            <!--            sporta Lista completa in xlsx-->
            <v-spacer></v-spacer>
            <v-text-field
                v-model="textSearch"
                append-icon="search"
                :label="$t('PAGES.USERS.LIST.TABLE.FILTER')"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              fixed-header
              loading-text="Loading... Please wait"
              class="elevation-1"
              :headers="tableData.headers"
              :pageCount="totalPages"
              :page="1"
              :server-items-length="totalResults"
              :items="dataTableList"
              :loading="eventLoading"
              :options.sync="options"
              item-key="ID"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :search="tableData.search"
          >
            <template v-slot:item.code="{ item }">
              {{ item.code }}
            </template>
            <template v-slot:item.status="{ item }">
                <span>
                  <v-icon color="green" v-if="item.status == true"
                  >mdi-check-circle</v-icon
                  >
                  <v-icon color="red" v-else>mdi-minus-circle</v-icon>
                </span>
            </template>
            <template v-slot:item.date_of_birth="{ item }">
              <span v-if="item.date_of_birth != null">{{
                  new Date(item.date_of_birth).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                  })
                }}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span v-if="item.createdAt != null"
              >{{
                  new Date(item.createdAt).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.reservation_date_time="{ item }">
              <span v-if="item.reservation_date_time != null"
              >{{
                  new Date(item.reservation_date_time).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.subscription_date="{ item }">
              <span v-if="item.subscription_date != null">{{
                  new Date(item.subscription_date).toLocaleDateString()
                }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text icon color="green">
                <v-icon
                    @click="$router.push('/reservations/overview/' + item.id)"
                >mdi-folder-account
                </v-icon
                >
              </v-btn>
              <v-btn text icon color="blue">
                <v-icon @click="manageItem(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn text icon color="red">
                <v-icon @click="deleteItem(item)">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                    >{{ $t("COMMON.DIALOG.DELETE_CONFIRM") }}
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                      >{{ $t("COMMON.DIALOG.ABORT") }}
                      </v-btn>
                      <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                      >{{ $t("COMMON.DIALOG.OK") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500px" :persistent="true">
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import moment from "moment";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {DELETE_EVENT,} from "@/core/services/store/events.module";
import {mapGetters} from "vuex";
import {FETCH_RESERVATIONS_FULL_LIST, FETCH_RESERVATIONS_LIST} from "@/core/services/store/reservations.module";
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

export default {
  name: "ListEventsForm",
  data() {
    return {
      fieldToSearch: ["code", "citizen.name", "citizen.surname", "citizen.fiscalCode", "createdAt"],
      textSearch: "",
      dialog_edit: false,
      totalPage: Number,
      totalResults: Number,
      filters: {
        query: [],
        filters: {
          _cmb_and: "_event_id,_filter_res",
          _event_id: {
            "event.id": {
              _eq: ""
            },
            _filter_res: {}
          },
          _filter_res: {}
        },
        sort: [
          {
            direction: "DESC",
            field: "id"
          }
        ],
        page: 1,
        limit: 10
      },
      filters_clean: {
        query: [],
        filters: {
          "event.id": {
            _eq: ""
          }
        },
        sort: [
          {
            direction: "DESC",
            field: "id"
          }
        ],
        page: 1,
        limit: 10
      },
      single_item_loading: false,
      dialog: false,
      dialogDelete: false,
      page: 1,
      totalItems: 0,
      numberOfPages: 1,
      options: {},
      itemsPerPage: 10,
      optionsLength: 6,
      pagination: {
        page: 1,
        rowsPerPage: 20,
        itemsPerPage: 20
      },
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [1, 10, 20, 50, 100],
        "items-per-page-text": this.$t("COMMON.TABLES.PAGINATION"),
      },

      // users_list_data: [],
      tableFilters: {},
      tableData: {
        headers: [
          {
            text: this.$t("COMMON.TABLES.COLUMN.CODE"),
            align: "left",
            sortable: true,
            value: "code"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.NAME"),
            value: "citizen.name"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.SURNAME"),
            value: "citizen.surname"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.FISCAL_CODE"),
            value: "citizen.fiscal_code"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.EVENT_TITLE"),
            value: "event.title"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.DOSE"),
            value: "dose.title"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.RESERVATION_DATE"),
            value: "reservation_date_time"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.REGISTRATION_DATE"),
            value: "createdAt"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.STATUS"),
            value: "status"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.OPERATION"),
            value: "action",
            sortable: false
          }
        ]
      },
      editedItem: {},
      defaultItem: {},
      item_to_edit: {},
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1,
      },
      json_fields: {
        "Codice": "code",
        "Data": {
          field: "reservation_date_time",
          callback: (value) => {
            return moment(value).format('DD-MM-YYYY');
          }
        },
        "Ora": {
          field: "reservation_date_time",
          callback: (value) => {
            return moment(value).format('HH:mm');
          }
        },
        "Creazione": {
          field: "createdAt",
          callback: (value) => {
            return moment(value).format('DD-MM-YYYY HH:mm');
          }
        },
        "CodiceCittadino": "citizen.code",
        "Nome": "citizen.name",
        "Cognome": "citizen.surname",
        "DataNascita": {
          field: "citizen.date_of_birth",
          callback: (value) => {
            return moment(value).format('DD-MM-YYYY');
          }
        },
        "CodiceFiscale": "citizen.fiscal_code",
        "Residenza": "citizen.city_of_residence.denomination",
        "Dose": "dose.title",
        "StatoPrenotazione": "status"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      filters_export: {
        query: [],
        filters: {
          "event.id": {
            _eq: ""
          }
        },
        sort: [
          {
            direction: "ASC",
            field: "reservationDateTime"
          }
        ],
        page: 1,
        limit: 10000
      },
      isExportable: false
    };
  },
  computed: {
    ...mapGetters(["reservationsList", "fullStateList", "eventLoading", "reservationsFullList", "reservationFullLoading"]),
    dataTableList() {
      return this.reservationsList.data;
    },
    event_date_time_from_formatted() {
      return this.tableFilters.event_date_time_from
          ? moment(String(this.tableFilters.event_date_time_from)).format(
              "DD/MM/YYYY"
          )
          : "";
    },
    event_date_time_to_formatted() {
      return this.tableFilters.event_date_time_to
          ? moment(String(this.tableFilters.event_date_time_to)).format(
              "DD/MM/YYYY"
          )
          : "";
    }
  },
  components: {},
  methods: {
    createFilterPerTable() {
      var filter = {};
      var cmb = "";
      var self = this;

      this.fieldToSearch.forEach(function (v) {
        var key1 = "_" + v.replace(".", "_");
        cmb += key1 + ",";
        filter[key1] = {};
        filter[key1][v] = {};
        if (self.textSearch != "") {
          filter[key1][v]["_like"] = "%" + self.textSearch + "%";
        } else {
          filter[key1][v]["_like"] = "%"
        }
      });
      filter._filter_res = {}
      filter._filter_res._cmb_or = cmb.slice(0, -1);
      filter._event_id = {};
      filter._event_id["event.id"] = {};
      filter._event_id["event.id"]._eq = this.$route.params.id;
      filter._cmb_and = "_filter_res,_event_id";
      return filter;
    },
    convertDataToPicker(date) {
      if (date != undefined) {
        return new Date(date).getDate();
      }
    },
    onClearDateClicked(type) {
      type == "from"
          ? (this.tableFilters.event_date_time_from = "")
          : (this.tableFilters.event_date_time_to = "");
    },
    readDataFromAPI(reset) {
      const {page, itemsPerPage, sortBy, sortDesc} = this.options;
      let pageNumber;
      if (reset == true) {
        pageNumber = 1;
        this.filters.limit = itemsPerPage;
      } else {
        pageNumber = page == 0 ? 1 : page;
        this.filters.limit = itemsPerPage;
      }
      this.filters.page = pageNumber;
      if (sortBy != undefined && sortBy.length > 0) {
        this.filters.sort = [
          {
            direction: sortDesc != undefined && sortDesc[0] ? "DESC" : "ASC",
            field: sortBy[0],
          },
        ];
      }
      this.filters.filters = this.createFilterPerTable();
      if (!this.eventLoading) {
        this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.filters);
      }
    },
    updateDialogLoading(status) {
      this.single_item_loading = status;
    },
    manageItem(item) {
      this.$router.push("/events/edit/" + item.id);
    },
    deleteItem(item) {
      this.editedIndex = this.dataTableList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.dataTableList.splice(this.editedIndex, 1);
      this.$store.dispatch(DELETE_EVENT, this.editedItem);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("PAGES.EVENTS.AREA")},
      {title: this.$t("PAGES.EVENTS.LIST.TITLE")}
    ]);
    this.filters_clean.filters["event.id"]._eq = this.$route.params.id
    this.filters_export.filters["event.id"]._eq = this.$route.params.id
    this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.filters_clean);
    this.$store.dispatch(FETCH_RESERVATIONS_FULL_LIST, this.filters_export);
  },
  watch: {
    reservationsFullList() {
      if (this.reservationsFullList.data.length > 0 && !this.reservationFullLoading) {
        this.isExportable = true;
      }
    },
    reservationsList() {
      this.totalPage = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total_pages
          : 0;
      this.totalResults = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total
          : 0;
      this.page = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total_pages
          : 0;
    },
    dialog(val) {
      val || this.close();
    },
    dialog_edit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.readDataFromAPI();
      }
    },
    textSearch: {
      handler() {
        this.readDataFromAPI();
      }
    }
  }
};
</script>
